import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";

@Pipe({
    name: "arcLabel",
})
export class ArcLabelPipe implements PipeTransform {
    constructor(private readonly translocoService: TranslocoService) {}

    public transform(arcValue: number | undefined, isInitial: boolean = false): string | undefined {
        if (arcValue === undefined || arcValue === null) {
            return undefined;
        }

        if (isInitial) {
            return this.translocoService.translate("dtmUi.initialArcLabel", { value: arcValue });
        }

        return this.translocoService.translate("dtmUi.residualArcLabel", { value: arcValue });
    }
}
