/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */

// NOTE: This code is based on https://github.com/lipton-ice-tea/leaflet-canvas-markers/blob/master/leaflet-canvas-markers.js

import { Canvas, CircleMarker, CircleMarkerOptions, LatLng, LatLngExpression, Map as LeafletMap } from "leaflet";

Canvas.include({
    _updateImg(layer: any) {
        const { icon } = layer.options;
        const point = layer._point.round();
        point.x += icon.offset.x;
        point.y += icon.offset.y;

        if (icon.rotate) {
            this._ctx.save();
            this._ctx.translate(point.x, point.y);
            this._ctx.rotate((icon.rotate * Math.PI) / 180);
            this._ctx.drawImage(icon.el, -icon.size[0] / 2, -icon.size[1] / 2, icon.size[0], icon.size[1]);
            this._ctx.restore();
        } else {
            this._ctx.drawImage(icon.el, point.x - icon.size[0] / 2, point.y - icon.size[1] / 2, icon.size[0], icon.size[1]);
        }
    },
});

function calculateAngleCoordinates(map: LeafletMap, prevLatlng: LatLng, latlng: LatLng) {
    if (!latlng || !prevLatlng) {
        return 0;
    }

    const pxStart = map.project(prevLatlng);
    const pxEnd = map.project(latlng);

    return (Math.atan2(pxStart.y - pxEnd.y, pxStart.x - pxEnd.x) / Math.PI) * 180 - 90;
}

const defaultIconOptions = {
    rotate: 0,
    size: [40, 40],
    offset: { x: 0, y: 0 },
};

const imagesMap = new Map<string, HTMLImageElement>();

function loadImage(url: string) {
    const existingImage = imagesMap.get(url);
    if (existingImage) {
        return existingImage;
    }

    const image = new Image();
    image.src = url;
    imagesMap.set(url, image);

    return image;
}

export const CanvasIconMarker = CircleMarker.extend({
    _updatePath() {
        if (!this.options.icon || !this.options.icon.url) {
            return;
        }
        if (!this.options.icon.el) {
            this.options.icon = { ...defaultIconOptions, ...this.options.icon };
            this.options.icon.rotate += calculateAngleCoordinates(this._map, this.options.prevLatlng, this._latlng);
            const image = loadImage(this.options.icon.url);
            this.options.icon.el = image;
            image.onload = () => {
                this.redraw();
            };
            image.onerror = () => {
                this.options.icon = null;
            };
        } else {
            this._renderer._updateImg(this);
        }
    },
});

export const createCanvasIconMarker = function (
    latlng: LatLngExpression,
    options: CircleMarkerOptions & { icon: { url: string } & Partial<typeof defaultIconOptions> }
) {
    if (!options.radius && options.icon && options.icon.size) {
        options.radius = Math.ceil(Math.max(...options.icon.size) / 2);
    }

    if (options.pane) {
        delete options.pane;
    }

    return new (CanvasIconMarker as any)(latlng, options);
};
