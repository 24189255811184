import { CdkAccordion, CdkAccordionItem } from "@angular/cdk/accordion";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { UniqueSelectionDispatcher } from "@angular/cdk/collections";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    Optional,
    Output,
    SkipSelf,
} from "@angular/core";
import { AnimationUtils, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { defer, startWith } from "rxjs";
import { filter } from "rxjs/operators";

interface ExpandablePanelComponentState {
    hasHeaderSeparator: boolean;
    isDisabled: boolean;
    wasExpandedBeforeParentCollapsed: boolean;
    isArrowIconReversed: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-expandable-panel",
    templateUrl: "./expandable-panel.component.html",
    styleUrls: ["./expandable-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimationWithMargin()],
    providers: [
        LocalComponentStore,
        { provide: CdkAccordion, useValue: undefined },
        { provide: CdkAccordionItem, useExisting: forwardRef(() => ExpandablePanelComponent) },
    ],
})
export class ExpandablePanelComponent extends CdkAccordionItem {
    @HostBinding("@blockInitialRenderAnimation") public get blockInitialRenderAnimation() {
        return true;
    }
    @HostBinding("class.accordion-item") public get isAccordionItemCssClass() {
        return !!this.accordion;
    }
    @HostBinding("class.expanded") public get isExpandedCssClass() {
        return this.expanded;
    }

    @Input() public set isExpanded(value: BooleanInput) {
        this.expanded = coerceBooleanProperty(value);
    }
    @Input() public set hasHeaderSeparator(value: BooleanInput) {
        this.localStore.patchState({ hasHeaderSeparator: coerceBooleanProperty(value) });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }
    @Input() public set isArrowIconReversed(value: BooleanInput) {
        this.localStore.patchState({ isArrowIconReversed: coerceBooleanProperty(value) });
    }
    @Output() public readonly toggleChange = new EventEmitter<boolean>();
    @Output() protected readonly expandablePanelOpen = new EventEmitter<boolean>();

    protected readonly isExpanded$ = defer(() => this.expandedChange.pipe(startWith(this.expanded)));
    protected readonly hasHeaderSeparator$ = this.localStore.selectByKey("hasHeaderSeparator");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly isArrowIconReversed$ = this.localStore.selectByKey("isArrowIconReversed");

    constructor(
        private readonly localStore: LocalComponentStore<ExpandablePanelComponentState>,
        changeDetectorRef: ChangeDetectorRef,
        @Optional() @SkipSelf() accordion: CdkAccordion,
        @Optional() expansionDispatcher: UniqueSelectionDispatcher,
        @Optional() @SkipSelf() parentExpandable?: CdkAccordionItem
    ) {
        super(accordion, changeDetectorRef, expansionDispatcher);
        this.localStore.setState({
            hasHeaderSeparator: true,
            isDisabled: false,
            wasExpandedBeforeParentCollapsed: this.expanded,
            isArrowIconReversed: false,
        });

        parentExpandable?.expandedChange.pipe(untilDestroyed(this)).subscribe((isExpanded) => {
            if (isExpanded) {
                this.expanded = this.localStore.selectSnapshotByKey("wasExpandedBeforeParentCollapsed");

                return;
            }

            this.localStore.patchState({ wasExpandedBeforeParentCollapsed: this.expanded });
            this.close();

            // TODO: this mechanism prevents animation error described in: https://github.com/angular/angular/issues/32815
        });

        this.expandedChange.pipe(filter(FunctionUtils.isTruthy), untilDestroyed(this)).subscribe((value) => {
            this.expandablePanelOpen.emit(value);
        });
    }

    protected togglePanel(isExpanded: boolean) {
        this.toggle();
        this.toggleChange.next(!isExpanded);
    }
}
