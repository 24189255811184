import { Pipe, PipeTransform } from "@angular/core";
import { DateUtils, ISO8601TimeDuration, TimeDurationItems } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";

@Pipe({
    name: "duration",
})
export class DurationPipe implements PipeTransform {
    constructor(private readonly transloco: TranslocoService) {}

    public transform(
        duration: ISO8601TimeDuration | undefined,
        format: "long" | "short" = "long",
        invalidLabel?: string
    ): string | undefined {
        if (duration === undefined) {
            return invalidLabel;
        }

        const durationParts = DateUtils.extractISO8601Duration(duration);

        if (durationParts === null) {
            return invalidLabel;
        }

        const result = this.formatDuration(durationParts, format);

        if (result === undefined) {
            return invalidLabel;
        }

        return result;
    }

    private formatDuration(
        { years, months, weeks, days, hours, minutes, seconds }: TimeDurationItems,
        format: "long" | "short"
    ): string | undefined {
        const result: string[] = [];
        const isLongFormat = format === "long";

        if (years) {
            result.push(this.transloco.translate(isLongFormat ? "dtmUi.duration.years" : "dtmUi.duration.yearsShort", { years }));
        }

        if (months) {
            result.push(this.transloco.translate(isLongFormat ? "dtmUi.duration.months" : "dtmUi.duration.monthsShort", { months }));
        }

        if (weeks) {
            result.push(this.transloco.translate(isLongFormat ? "dtmUi.duration.weeks" : "dtmUi.duration.weeksShort", { weeks }));
        }

        if (days) {
            result.push(this.transloco.translate(isLongFormat ? "dtmUi.duration.days" : "dtmUi.duration.daysShort", { days }));
        }

        if (hours) {
            result.push(this.transloco.translate(isLongFormat ? "dtmUi.duration.hours" : "dtmUi.duration.hoursShort", { hours }));
        }

        if (minutes) {
            result.push(this.transloco.translate(isLongFormat ? "dtmUi.duration.minutes" : "dtmUi.duration.minutesShort", { minutes }));
        }

        if (seconds) {
            result.push(this.transloco.translate(isLongFormat ? "dtmUi.duration.seconds" : "dtmUi.duration.secondsShort", { seconds }));
        }

        if (result.length === 0) {
            return undefined;
        }

        return result.join(" ");
    }
}
