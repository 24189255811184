import turfDifference from "@turf/difference";
import {
    Feature,
    feature as turfFeature,
    FeatureCollection,
    featureCollection as turfFeatureCollection,
    MultiPolygon,
    Polygon,
} from "@turf/helpers";

function checkIfIsArrayOfFeatures(
    features: (Polygon | MultiPolygon)[] | Feature<Polygon | MultiPolygon>[]
): features is Feature<Polygon | MultiPolygon>[] {
    return features[0].type === "Feature";
}

export function difference(
    features: FeatureCollection<Polygon | MultiPolygon> | (Polygon | MultiPolygon)[] | Feature<Polygon | MultiPolygon>[]
): Feature<Polygon | MultiPolygon> | null {
    let collection: FeatureCollection<Polygon | MultiPolygon>;

    if (Array.isArray(features)) {
        if (checkIfIsArrayOfFeatures(features)) {
            collection = turfFeatureCollection(features);
        } else {
            collection = turfFeatureCollection(features.map((feature) => turfFeature(feature)));
        }
    } else {
        collection = features;
    }

    if (collection.features.length === 0) {
        return null;
    }

    if (collection.features.length === 1) {
        return collection.features[0];
    }

    return turfDifference(collection);
}
