import { BreakpointObserver } from "@angular/cdk/layout";
import { ElementRef, Injectable } from "@angular/core";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { BehaviorSubject, debounceTime, finalize, Observable, Subject } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

const DESKTOP_WIDE_BREAKPOINT = 1440;
const DESKTOP_BREAKPOINT = 1025;
const TABLET_BREAKPOINT = 768;
const SMARTPHONE_WIDE_BREAKPOINT = 431;

const BREAKPOINT_BUFFER = 0.02;

const DESKTOP_WIDE_MEDIA_QUERY = `(min-width: ${DESKTOP_WIDE_BREAKPOINT}px)`;
const DESKTOP_MEDIA_QUERY = `(min-width: ${DESKTOP_BREAKPOINT}px) and (max-width: ${DESKTOP_WIDE_BREAKPOINT - BREAKPOINT_BUFFER}px)`;
const TABLET_MEDIA_QUERY = `(min-width: ${TABLET_BREAKPOINT}px) and (max-width: ${DESKTOP_BREAKPOINT - BREAKPOINT_BUFFER}px)`;
const SMARTPHONE_WIDE_MEDIA_QUERY = `(min-width: ${SMARTPHONE_WIDE_BREAKPOINT}px) and (max-width: ${
    TABLET_BREAKPOINT - BREAKPOINT_BUFFER
}px)`;
const SMARTPHONE_MEDIA_QUERY = `(max-width: ${SMARTPHONE_WIDE_BREAKPOINT - BREAKPOINT_BUFFER}px)`;
const DEFAULT_RESIZE_OBSERVER_DEBOUNCE_TIME = 200;

export enum DeviceSize {
    DesktopWide = "DESKTOP_WIDE",
    Desktop = "DESKTOP",
    Tablet = "TABLET",
    SmartphoneWide = "SMARTPHONE_WIDE",
    Smartphone = "SMARTPHONE",
}

@Injectable({
    providedIn: "root",
})
export class DeviceSizeService {
    private deviceSizeSubject = new BehaviorSubject({
        [DeviceSize.DesktopWide]: false,
        [DeviceSize.Desktop]: false,
        [DeviceSize.Tablet]: false,
        [DeviceSize.SmartphoneWide]: false,
        [DeviceSize.Smartphone]: false,
    });

    public isSize(...sizes: DeviceSize[]): boolean {
        const deviceSizeInfo = this.deviceSizeSubject.getValue();

        return sizes.reduce((result, size) => result || deviceSizeInfo[size], false);
    }

    public getSizeObservable(...sizes: DeviceSize[]): Observable<boolean> {
        return this.deviceSizeSubject.asObservable().pipe(
            map((deviceSizeInfo) => sizes.reduce((result, size) => result || deviceSizeInfo[size], false)),
            distinctUntilChanged()
        );
    }

    constructor(private readonly breakpointObserver: BreakpointObserver) {
        this.breakpointObserver
            .observe([
                DESKTOP_WIDE_MEDIA_QUERY,
                DESKTOP_MEDIA_QUERY,
                TABLET_MEDIA_QUERY,
                SMARTPHONE_WIDE_MEDIA_QUERY,
                SMARTPHONE_MEDIA_QUERY,
            ])
            .subscribe(({ breakpoints }) => {
                this.deviceSizeSubject.next({
                    [DeviceSize.DesktopWide]: this.isDesktopWideBreakpoint(breakpoints),
                    [DeviceSize.Desktop]: this.isDesktopBreakpoint(breakpoints),
                    [DeviceSize.Tablet]: this.isTabletBreakpoint(breakpoints),
                    [DeviceSize.SmartphoneWide]: this.isSmartphoneWideBreakpoint(breakpoints),
                    [DeviceSize.Smartphone]: this.isSmartphoneBreakpoint(breakpoints),
                });
            });
    }

    public observeElementResize(element: ElementRef, resizeObserverDebounceTime = DEFAULT_RESIZE_OBSERVER_DEBOUNCE_TIME) {
        const resizeObserverSubject = new Subject<ResizeObserverEntry>();
        const resizeObserver = new ResizeObserver((entries) => {
            resizeObserverSubject.next(entries[0]);
        });

        resizeObserver.observe(element.nativeElement);

        return resizeObserverSubject.asObservable().pipe(
            RxjsUtils.filterFalsy(),
            debounceTime(resizeObserverDebounceTime),
            finalize(() => {
                resizeObserver.disconnect();
            })
        );
    }

    private isDesktopWideBreakpoint(breakpoints: { [p: string]: boolean }): boolean {
        return breakpoints[DESKTOP_WIDE_MEDIA_QUERY] ?? false;
    }

    private isDesktopBreakpoint(breakpoints: { [p: string]: boolean }): boolean {
        return breakpoints[DESKTOP_MEDIA_QUERY] ?? false;
    }

    private isTabletBreakpoint(breakpoints: { [p: string]: boolean }): boolean {
        return breakpoints[TABLET_MEDIA_QUERY] ?? false;
    }

    private isSmartphoneWideBreakpoint(breakpoints: { [p: string]: boolean }): boolean {
        return breakpoints[SMARTPHONE_WIDE_MEDIA_QUERY] ?? false;
    }

    private isSmartphoneBreakpoint(breakpoints: { [p: string]: boolean }): boolean {
        return breakpoints[SMARTPHONE_MEDIA_QUERY] ?? false;
    }
}
