import { SelectionChange, SelectionModel } from "@angular/cdk/collections";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    forwardRef,
    Output,
    QueryList,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatLegacyCheckboxChange as MatCheckboxChange } from "@angular/material/legacy-checkbox";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CheckboxFieldComponent } from "../checkbox-field/checkbox-field.component";

@UntilDestroy()
@Component({
    selector: "dtm-ui-checkbox-group",
    template: "<ng-content></ng-content>",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxGroupComponent),
            multi: true,
        },
    ],
})
export class CheckboxGroupComponent implements AfterViewInit, ControlValueAccessor {
    @ContentChildren(CheckboxFieldComponent, { descendants: true }) public checkboxes!: QueryList<CheckboxFieldComponent>;

    @Output() public readonly selectionChange = new EventEmitter<SelectionChange<string>>();

    public readonly selectedOptions = new SelectionModel<string>(true);
    public onChange: (value: string[]) => void = FunctionUtils.noop;
    public onTouched: () => void = FunctionUtils.noop;

    constructor() {
        this.selectedOptions.changed.pipe(untilDestroyed(this)).subscribe((change) => this.updateValue(change));
    }

    public ngAfterViewInit(): void {
        this.onCheckboxesCheckedChange();
        this.updateCheckboxesState();
    }

    public writeValue(obj: string[]): void {
        if (!Array.isArray(obj)) {
            return;
        }

        this.selectedOptions.clear();
        this.selectedOptions.select(...obj);

        this.updateCheckboxesState();
    }

    public registerOnChange(fn: (value: string[]) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    private updateValue(change: SelectionChange<string>) {
        this.onChange(change.source.selected);
        this.onTouched();
        this.selectionChange.emit(change);
    }

    private updateCheckboxesState(): void {
        if (!this.checkboxes) {
            return;
        }

        const selectedValues = this.selectedOptions.selected;

        this.checkboxes.forEach((checkbox) => {
            checkbox.checked = selectedValues.includes(checkbox.value);
            // NOTE: Method _onLabelTextChange triggers change detection in checkbox component
            // eslint-disable-next-line no-underscore-dangle
            checkbox._onLabelTextChange();
        });
    }

    private onCheckboxesCheckedChange(): void {
        this.checkboxes.forEach((checkbox) =>
            checkbox.change.pipe(untilDestroyed(this)).subscribe(({ checked }: MatCheckboxChange) => {
                const { value } = checkbox;

                if (checked) {
                    this.selectedOptions.select(value);
                } else {
                    this.selectedOptions.deselect(value);
                }
            })
        );
    }
}
