import {
    HttpContextToken,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { BehaviorSubject, filter, Observable, switchMap, take, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthActions } from "../state/auth.actions";
import { AuthState } from "../state/auth.state";

export const SKIP_AUTHENTICATION_OFFLINE_CAPABLE_HTTP_INTERCEPTOR = new HttpContextToken(() => true);

@Injectable()
export class AuthenticationOfflineCapableInterceptor implements HttpInterceptor {
    private readonly isTokenRefreshingSubject = new BehaviorSubject<boolean>(false);
    private readonly isTokenRefreshing$ = this.isTokenRefreshingSubject.asObservable();

    constructor(private readonly store: Store) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.context.has(SKIP_AUTHENTICATION_OFFLINE_CAPABLE_HTTP_INTERCEPTOR)) {
            return next.handle(request);
        }

        return next.handle(this.addAuthenticationToken(request)).pipe(
            catchError((error) => {
                // NOTE: it's NOT an inactive token error
                if (error instanceof HttpErrorResponse && error.status !== HttpStatusCode.Unauthorized) {
                    return throwError(() => error);
                }

                // NOTE: the token is being refreshed
                if (this.isTokenRefreshingSubject.getValue()) {
                    return this.isTokenRefreshing$.pipe(
                        filter((isRefreshing) => !isRefreshing),
                        take(1),
                        switchMap(() => next.handle(this.addAuthenticationToken(request)))
                    );
                }

                this.isTokenRefreshingSubject.next(true);

                // NOTE: refresh token action
                return this.store.dispatch(new AuthActions.UpdateToken()).pipe(
                    switchMap(() => {
                        this.isTokenRefreshingSubject.next(false);

                        return next.handle(this.addAuthenticationToken(request));
                    })
                );
            })
        );
    }

    private addAuthenticationToken(httpRequest: HttpRequest<unknown>): HttpRequest<unknown> {
        const token = this.store.selectSnapshot(AuthState.token);

        return token ? httpRequest.clone({ setHeaders: { Authorization: `Bearer ${token}` } }) : httpRequest;
    }
}
