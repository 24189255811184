import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NgControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { DEFAULT_PHONE_COUNTRY_CODE, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import equal from "fast-deep-equal";
import { getCountryCallingCode, NumberType } from "libphonenumber-js";
import { CountryCode, getCountries, isValidPhoneNumber, NationalNumber, parsePhoneNumber } from "libphonenumber-js/max";
import { distinctUntilChanged } from "rxjs";
import { startWith } from "rxjs/operators";
import listOfCountryNames from "../../../assets/countries/countries.json";
import alpha2to3Map from "../../../assets/countries/countries2to3.json";
import { Alpha2CountryCode } from "../../models/country.models";
import { PhoneNumber, PhoneNumberCountryListItem } from "../../models/phone-number.models";
import { SelectFieldComponent } from "../select-field/select-field.component";

interface PhoneNumberFieldComponentState {
    selectedCountry: PhoneNumberCountryListItem | undefined;
    searchPlaceholder: string | undefined;
    hasErrors: boolean;
    dropdownWidth: number;
    id: string;
}

export const SMS_CAPABLE_NUMBER_TYPES = ["MOBILE", "FIXED_LINE_OR_MOBILE"] as NumberType[];

export function validPhoneNumberWithCountryCode(control: AbstractControl): ValidationErrors | null {
    const countryCodeValue = control.value?.countryCode;
    const numberValue = control.value?.number;

    if (countryCodeValue && numberValue && !isValidPhoneNumber(numberValue, countryCodeValue)) {
        return { invalidNumber: true };
    }

    return null;
}

export const allowedPhoneNumberType =
    (validNumberTypes: NumberType[]): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
        const countryCodeValue = control.value?.countryCode;
        const numberValue = control.value?.number;

        if (numberValue && countryCodeValue && isValidPhoneNumber(numberValue, countryCodeValue)) {
            const phoneNumber = parsePhoneNumber(numberValue, countryCodeValue);
            if (!validNumberTypes.includes(phoneNumber.getType())) {
                return { invalidNumberType: true };
            }

            return null;
        }

        return null;
    };

export function phoneNumberAndCountryCodeRequired(control: AbstractControl): ValidationErrors | null {
    const countryCodeValue = control.value?.countryCode;
    const numberValue = control.value?.number;

    if (!numberValue || !countryCodeValue) {
        return { required: true };
    }

    return null;
}

export const requiredValidForSmsPhoneNumberValidator = Validators.compose([
    phoneNumberAndCountryCodeRequired,
    allowedPhoneNumberType(SMS_CAPABLE_NUMBER_TYPES),
    validPhoneNumberWithCountryCode,
]);

@UntilDestroy()
@Component({
    selector: "dtm-ui-phone-number-field",
    templateUrl: "./phone-number-field.component.html",
    styleUrls: ["./phone-number-field.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoneNumberFieldComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PhoneNumberFieldComponent),
            multi: true,
        },
    ],
})
export class PhoneNumberFieldComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, Validator {
    private static ID_COUNTER = 0;

    @Input() public set phoneNumber(value: PhoneNumber | null) {
        if (value) {
            this.phoneNumberForm.setValue({
                countryCode: value.countryCode ?? DEFAULT_PHONE_COUNTRY_CODE,
                number: value.number,
            });
            if (!this.validate()) {
                this.propagateChange(this.phoneNumberForm.getRawValue());
            }

            this.onValidationChange();
        } else {
            this.phoneNumberForm.setValue({
                countryCode: DEFAULT_PHONE_COUNTRY_CODE,
                number: "",
            });
        }
        this.assignSelectedCountry(value?.countryCode ?? DEFAULT_PHONE_COUNTRY_CODE);
    }

    @Input() public set searchPlaceholder(value: string | undefined) {
        this.localStore.patchState({ searchPlaceholder: value });
    }

    @ViewChild("formContainer") public formContainer!: ElementRef;
    @ViewChild("search") public search!: ElementRef;
    @ViewChild("selectTemplate") public select!: SelectFieldComponent;

    protected readonly countryFormControl = new FormControl<CountryCode | null>(null);
    protected readonly phoneNumberControl = new FormControl<NationalNumber>("", { nonNullable: true });
    protected readonly searchControl = new FormControl<string>("", { nonNullable: true });
    protected readonly phoneNumberForm = new FormGroup({
        countryCode: this.countryFormControl,
        number: this.phoneNumberControl,
    });

    protected readonly hasErrors$ = this.localStore.selectByKey("hasErrors");
    protected readonly selectedCountry$ = this.localStore.selectByKey("selectedCountry");
    protected listOfCountriesDisplay!: PhoneNumberCountryListItem[];
    protected readonly dropdownWidth$ = this.localStore.selectByKey("dropdownWidth");
    protected readonly defaultListOfCountries = getCountries()
        .map((countryCode: CountryCode): PhoneNumberCountryListItem => {
            const [countryEnglishName, countryNativeName] = listOfCountryNames[countryCode];
            const countryNumber = (getCountryCallingCode(countryCode) ?? "").toString();
            const displayName = countryNativeName
                ? `${countryEnglishName} (${countryNativeName}) (+${countryNumber})`
                : `${countryEnglishName} (+${countryNumber})`;

            return {
                alpha2Code: countryCode as Alpha2CountryCode,
                countryNumber,
                displayName,
                alpha3Code: alpha2to3Map[countryCode as Alpha2CountryCode],
            };
        })
        .sort(this.sortCountries);
    protected readonly id$ = this.localStore.selectByKey("id");

    private formContainerResizeObserver!: ResizeObserver;

    constructor(private readonly injector: Injector, private readonly localStore: LocalComponentStore<PhoneNumberFieldComponentState>) {
        localStore.setState({
            selectedCountry: undefined,
            searchPlaceholder: undefined,
            hasErrors: false,
            dropdownWidth: 300,
            id: this.generateId(),
        });
    }

    protected propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: PhoneNumber) => void = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    public ngOnInit(): void {
        this.countryFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.assignSelectedCountry(value);
            this.searchControl.reset("");
        });

        this.phoneNumberForm.valueChanges.pipe(distinctUntilChanged(equal), untilDestroyed(this)).subscribe((value) => {
            // NOTE replaces all non digit characters with "" so phone number consists only numbers
            const phoneNumber = value.number?.replace(/\D/g, "");
            this.phoneNumberForm.controls.number.setValue(phoneNumber, { emitEvent: false });
            this.propagateChange(this.phoneNumberForm.getRawValue());
        });

        this.searchControl.valueChanges.pipe(startWith(""), untilDestroyed(this)).subscribe((searchValue) => {
            this.listOfCountriesDisplay = this.defaultListOfCountries.filter((country) =>
                country.displayName.toLowerCase().includes(searchValue.toLowerCase())
            );
        });
    }

    public ngAfterViewInit() {
        this.formContainerResizeObserver = new ResizeObserver(([entry]) => {
            this.localStore.patchState({
                dropdownWidth: entry.contentRect.width,
            });
        });
        this.formContainerResizeObserver.observe(this.formContainer.nativeElement);

        const parentControl = this.injector.get(NgControl, null)?.control;
        parentControl?.statusChanges.pipe(untilDestroyed(this)).subscribe((status) => {
            this.phoneNumberControl.setErrors(status === "INVALID" ? { wrongNumber: true } : null);
        });
    }

    public ngOnDestroy() {
        this.formContainerResizeObserver?.unobserve(this.formContainer.nativeElement);
    }

    public validate(): ValidationErrors | null {
        return null;
    }

    public registerOnChange(fn: (value: PhoneNumber) => void): void {
        this.propagateChange = fn;
    }

    public phoneSelectKeyDown(event: KeyboardEvent): void {
        if (event.key === "Tab") {
            return;
        }
        event.stopPropagation();
        this.search.nativeElement.focus();
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public writeValue(value: PhoneNumber | null): void {
        this.phoneNumber = value;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.phoneNumberForm.disable();
        } else {
            this.phoneNumberForm.enable();
        }
    }

    protected countryListItemTrack(_: number, item: PhoneNumberCountryListItem): Alpha2CountryCode {
        return item.alpha2Code;
    }

    protected assignSelectedCountryCode() {
        const activeOption = this.select.options.find((option) => option.active);

        if (activeOption) {
            this.countryFormControl.setValue(activeOption.value);

            return;
        }

        if (!this.listOfCountriesDisplay.length) {
            return;
        }

        this.countryFormControl.setValue(this.listOfCountriesDisplay[0].alpha2Code as CountryCode);
    }

    private sortCountries(rightCountry: PhoneNumberCountryListItem, leftCountry: PhoneNumberCountryListItem): number {
        const rightCountryCompare = rightCountry.displayName.toLowerCase();
        const leftCountryCompare = leftCountry.displayName.toLowerCase();

        return new Intl.Collator().compare(rightCountryCompare ?? "", leftCountryCompare ?? "");
    }

    private assignSelectedCountry(value: CountryCode | null): void {
        this.localStore.patchState({
            selectedCountry: this.getCountryListItem(value),
        });
    }

    private getCountryListItem(countryCode: Alpha2CountryCode | CountryCode | null): PhoneNumberCountryListItem | undefined {
        return (
            this.defaultListOfCountries.find((country) => country.alpha2Code === countryCode) ??
            this.defaultListOfCountries.find((country) => country.alpha2Code === DEFAULT_PHONE_COUNTRY_CODE)
        );
    }

    private generateId(): string {
        return `phone-number-field-${PhoneNumberFieldComponent.ID_COUNTER++}`;
    }
}
