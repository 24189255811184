import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { IdentityDocumentType } from "../models/identity-document.models";

@Pipe({
    name: "identityDocumentType",
})
export class IdentityDocumentTypePipe implements PipeTransform {
    constructor(private readonly translocoService: TranslocoService) {}

    public transform(value: IdentityDocumentType | undefined | null): string | undefined {
        if (!value) {
            return undefined;
        }

        return this.mapToTranslatedDocumentType(value);
    }

    private mapToTranslatedDocumentType(docType: IdentityDocumentType): string {
        return {
            [IdentityDocumentType.IdCard]: this.translocoService.translate("dtmUi.documentType.idCardLabel"),
            [IdentityDocumentType.Passport]: this.translocoService.translate("dtmUi.documentType.passportLabel"),
        }[docType];
    }
}
