import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, forwardRef, Inject, Input, ViewChild } from "@angular/core";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Control, ControlPosition, Map, MapOptions, TileLayer } from "leaflet";
import { filter, firstValueFrom, map } from "rxjs";
import { DEFAULT_AZURE_MAPS_OPTIONS, RENDER_V2_TILE_URL } from "../../../shared/defaults/azure-maps.defaults";
import { AzureMapsOptions, AzureMapsTileset, AzureMapsTilesetOptions } from "../../../shared/models/azure-maps.models";
import { AZURE_MAPS_SUBSCRIPTION_KEY } from "../../../shared/shared-map.tokens";
import { LeafletMapConfig, LeafletMapProvider, LEAFLET_MAP_CONFIG, LEAFLET_MAP_PROVIDER } from "../../leaflet-map.tokens";
import { TilesetStyle } from "../../models/leaflet-map.models";
import { LeafletUserPositionComponent } from "../leaflet-user-position/leaflet-user-position.component";

interface LeafletMapComponentState {
    currentZoom: number;
    isInitialized: boolean;
    mapOptions: MapOptions;
    tilesetStyle?: TilesetStyle;
}

const MAP_LAYER_OPTIONS: AzureMapsOptions = DEFAULT_AZURE_MAPS_OPTIONS;
const TILE_URL = RENDER_V2_TILE_URL;
const ATTRIBUTION = `&copy; ${MAP_LAYER_OPTIONS.timeStamp?.getFullYear() ?? ""} ${
    AzureMapsTilesetOptions[MAP_LAYER_OPTIONS.tilesetId].partnerCredits
}, Microsoft`;

@UntilDestroy()
@Component({
    selector: "dtm-map-leaflet-map",
    templateUrl: "./leaflet-map.component.html",
    styleUrls: ["./leaflet-map.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: LEAFLET_MAP_PROVIDER,
            useExisting: forwardRef(() => LeafletMapComponent),
        },
    ],
})
export class LeafletMapComponent implements LeafletMapProvider {
    @ViewChild("mapElement")
    public set mapElement(value: ElementRef) {
        this.initMap(value.nativeElement);
        this.localStore.patchState({ isInitialized: true });
    }
    @ViewChild("topLeftControls", { static: true }) private topLeftControls!: ElementRef;
    @ViewChild("topRightControls", { static: true }) private topRightControls!: ElementRef;
    @ViewChild("bottomRightControls", { static: true }) private bottomRightControls!: ElementRef;

    @ContentChild(LeafletUserPositionComponent) public userPositionComponent: LeafletUserPositionComponent | undefined;

    // NOTE: should be this way, using with local store cause map rendering issues
    @Input() public showZoomButtons = true;

    @Input() public set mapOptions(value: MapOptions) {
        this.localStore.patchState({ mapOptions: value });
    }

    @Input() public set tilesetStyle(value: TilesetStyle) {
        this.localStore.patchState({ tilesetStyle: value });
    }

    protected readonly currentZoom$ = this.localStore.selectByKey("currentZoom");
    private readonly isInitialized$ = this.localStore.selectByKey("isInitialized");
    private readonly tilesetStyle$ = this.localStore.selectByKey("tilesetStyle");

    private map!: Map;
    private tileLayerLabels?: TileLayer;
    private tileLayerBackground?: TileLayer;

    constructor(
        @Inject(AZURE_MAPS_SUBSCRIPTION_KEY) private readonly azureMapsSubscriptionKey: string,
        @Inject(LEAFLET_MAP_CONFIG) public readonly config: LeafletMapConfig,
        private readonly localStore: LocalComponentStore<LeafletMapComponentState>
    ) {
        this.localStore.setState({
            isInitialized: false,
            mapOptions: {
                center: this.config.defaultPosition,
                zoom: this.config.zoom.initial,
                minZoom: this.config.zoom.min,
                maxZoom: this.config.zoom.max,
                zoomControl: false,
            },
            currentZoom: this.config.zoom.initial,
        });

        this.tilesetStyle$
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((tilesetStyle) => this.handleTilesetStyle(tilesetStyle));
    }

    public getMap(): Promise<Map> {
        return firstValueFrom(
            this.isInitialized$.pipe(
                filter(FunctionUtils.isTruthy),
                map(() => this.map),
                untilDestroyed(this)
            )
        );
    }

    public getControlSections(): Promise<ElementRef[]> {
        return firstValueFrom(
            this.isInitialized$.pipe(
                filter(FunctionUtils.isTruthy),
                map(() => [this.topLeftControls, this.topRightControls, this.bottomRightControls]),
                untilDestroyed(this)
            )
        );
    }

    protected zoomIn() {
        this.map.setZoom(this.map.getZoom() + 1);
    }

    protected zoomOut() {
        this.map.setZoom(this.map.getZoom() - 1);
    }

    private initMap(mapContainer: HTMLElement): void {
        const resizeObserver = new ResizeObserver(() => this.map.invalidateSize());
        this.map = new Map(mapContainer, this.localStore.selectSnapshotByKey("mapOptions"));
        const tilesetStyle = this.localStore.selectSnapshotByKey("tilesetStyle");
        if (tilesetStyle) {
            this.handleTilesetStyle(tilesetStyle);
        } else {
            this.tileLayerBackground = this.createTileLayer(MAP_LAYER_OPTIONS.tilesetId);
            this.map.addLayer(this.tileLayerBackground);
        }

        resizeObserver.observe(mapContainer);
        this.addControlsPanel(this.topLeftControls, "topleft");
        this.addControlsPanel(this.topRightControls, "topright");
        this.addControlsPanel(this.bottomRightControls, "bottomright");

        this.map.on("zoom", () => this.localStore.patchState({ currentZoom: this.map.getZoom() }));
    }

    private addControlsPanel(panel: ElementRef, position: ControlPosition): void {
        const ControlPanel = Control.extend({
            options: { position },
            onAdd: () => panel?.nativeElement,
        });

        this.map.addControl(new ControlPanel());
    }

    private getFormattedTileUrl(url: string): string {
        url = url.replace(/{{/g, "{").replace(/}}/g, "}");
        url += "&subscription-key={subscriptionKey}";

        return url;
    }

    private createTileLayer(azureMapsTileset: AzureMapsTileset) {
        const tileOptions = {
            attribution: ATTRIBUTION,
            subscriptionKey: this.azureMapsSubscriptionKey,
            azMapsDomain: MAP_LAYER_OPTIONS.url,
            tilesetId: AzureMapsTilesetOptions[azureMapsTileset].tilesetId,
            tileSize: MAP_LAYER_OPTIONS.tileSize,
            language: MAP_LAYER_OPTIONS.language,
            view: "auto",
        };

        return new TileLayer(this.getFormattedTileUrl(TILE_URL), tileOptions);
    }

    private handleTilesetStyle(tilesetStyle: TilesetStyle) {
        if (!this.map) {
            return;
        }

        if (this.tileLayerLabels) {
            this.map.removeLayer(this.tileLayerLabels);
        }
        if (this.tileLayerBackground) {
            this.map.removeLayer(this.tileLayerBackground);
        }

        switch (tilesetStyle) {
            case TilesetStyle.BaseRoad:
                this.tileLayerBackground = this.createTileLayer(AzureMapsTileset.BaseRoad);
                this.map.addLayer(this.tileLayerBackground);
                this.tileLayerBackground.bringToBack();
                break;

            case TilesetStyle.ImageryBaseHybridRoad:
                this.tileLayerLabels = this.createTileLayer(AzureMapsTileset.BaseHybridRoad);
                this.map.addLayer(this.tileLayerLabels);
                this.tileLayerLabels.bringToBack();
                this.tileLayerBackground = this.createTileLayer(AzureMapsTileset.Imagery);
                this.map.addLayer(this.tileLayerBackground);
                this.tileLayerBackground.bringToBack();
                break;
        }
    }
}
