import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DEFAULT_PHONE_COUNTRY_CODE, LocalComponentStore, RxjsUtils, VERIFICATION_CODE_MASK } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { PhoneNumber } from "../../models/phone-number.models";
import { DataChangeActionType, DataUpdateAction, PhoneNumberFormValues } from "../../models/user-contact.models";

interface EditPhoneNumberComponentState {
    isRequestedPhoneChange: boolean;
    phoneNumber: PhoneNumber | undefined;
    isVerificationRequired: boolean;
    isEditMode: boolean;
    hasPhoneNumberConflictError: boolean;
    saveButtonLabel: string | undefined;
    verificationCodeMask: string | undefined;
    phoneNumberChangeDescription: string | undefined;
    shouldCheckIfPhoneNumberSameValue: boolean;
    isDisabled: boolean;
}

@Component({
    selector: "dtm-ui-edit-phone-number[phoneNumber][isRequestedPhoneChange]",
    templateUrl: "./edit-phone-number.component.html",
    styleUrls: ["./edit-phone-number.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EditPhoneNumberComponent {
    @Input() public set phoneNumber(value: PhoneNumber | undefined) {
        this.localStore.patchState({ phoneNumber: value });
    }
    @Input() public set isRequestedPhoneChange(value: BooleanInput) {
        this.localStore.patchState({ isRequestedPhoneChange: coerceBooleanProperty(value) });
    }
    @Input() public set hasPhoneNumberConflictError(value: BooleanInput) {
        this.localStore.patchState({ hasPhoneNumberConflictError: coerceBooleanProperty(value) });
    }
    @Input() public set isVerificationRequired(value: BooleanInput) {
        this.localStore.patchState({ isVerificationRequired: coerceBooleanProperty(value) });
    }
    @Input() public set isEditMode(value: BooleanInput) {
        this.localStore.patchState({ isEditMode: coerceBooleanProperty(value) });
    }
    @Input() public set saveButtonLabel(value: string | undefined) {
        this.localStore.patchState({ saveButtonLabel: value });
    }
    @Input() public set verificationCodeMask(value: string | undefined) {
        this.localStore.patchState({ verificationCodeMask: value });
    }
    @Input() public set phoneNumberChangeDescription(value: string | undefined) {
        this.localStore.patchState({ phoneNumberChangeDescription: value });
    }
    @Input() public set shouldCheckIfPhoneNumberSameValue(value: BooleanInput) {
        this.localStore.patchState({ shouldCheckIfPhoneNumberSameValue: coerceBooleanProperty(value) });
    }
    @Input() public set isDisabled(value: BooleanInput) {
        if (value) {
            this.localStore.patchState({ isEditMode: false });
        }
        this.localStore.patchState({ isDisabled: coerceBooleanProperty(value) });
    }

    @Output() public readonly requestDataChange: EventEmitter<DataUpdateAction> = new EventEmitter<DataUpdateAction>();
    @Output() public readonly closePanel: EventEmitter<void> = new EventEmitter<void>();

    protected readonly phoneNumber$ = this.localStore.selectByKey("phoneNumber").pipe(RxjsUtils.filterFalsy());
    protected readonly isRequestedPhoneChange$ = this.localStore.selectByKey("isRequestedPhoneChange");
    protected readonly hasPhoneNumberConflictError$ = this.localStore.selectByKey("hasPhoneNumberConflictError");
    protected readonly isVerificationRequired$ = this.localStore.selectByKey("isVerificationRequired");
    protected readonly verificationCodeMask$ = this.localStore.selectByKey("verificationCodeMask");
    protected readonly shouldCheckIfPhoneNumberSameValue$ = this.localStore.selectByKey("shouldCheckIfPhoneNumberSameValue");
    protected readonly phoneNumberChangeDescription$ = this.localStore.selectByKey("phoneNumberChangeDescription");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly isEditMode$ = combineLatest([
        this.localStore.selectByKey("isEditMode"),
        this.isRequestedPhoneChange$,
        this.isVerificationRequired$,
    ]).pipe(
        map(([isEditMode, isRequestedPhoneChange, isVerificationRequired]) =>
            isVerificationRequired ? isRequestedPhoneChange : isEditMode
        )
    );

    protected readonly phoneNumberFormControl = new FormControl<PhoneNumberFormValues>(
        {
            phone: { number: "", countryCode: DEFAULT_PHONE_COUNTRY_CODE },
            verificationCode: "",
        },
        { nonNullable: true }
    );

    protected readonly saveButtonLabel$ = combineLatest([
        this.localStore.selectByKey("saveButtonLabel"),
        this.isRequestedPhoneChange$,
    ]).pipe(
        map(([saveButtonLabel, isRequestedPhoneChange]) => {
            if (saveButtonLabel) {
                return saveButtonLabel;
            } else if (isRequestedPhoneChange) {
                return this.translocoService.translate("dtmUi.editPhoneNumber.verifyButtonLabel");
            }

            return this.translocoService.translate("dtmUi.editPhoneNumber.sendCodeButtonLabel");
        })
    );

    protected readonly DEFAULT_PHONE_COUNTRY_CODE = DEFAULT_PHONE_COUNTRY_CODE;

    constructor(
        private readonly localStore: LocalComponentStore<EditPhoneNumberComponentState>,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            phoneNumber: undefined,
            isRequestedPhoneChange: false,
            isVerificationRequired: true,
            isEditMode: false,
            hasPhoneNumberConflictError: false,
            saveButtonLabel: undefined,
            verificationCodeMask: VERIFICATION_CODE_MASK,
            phoneNumberChangeDescription: undefined,
            shouldCheckIfPhoneNumberSameValue: true,
            isDisabled: false,
        });
    }

    protected requestUserPhoneNumberChange(isRequestedPhoneNumberChange: boolean): void {
        const verificationCode = this.phoneNumberFormControl.value.verificationCode;
        const phone = this.phoneNumberFormControl.value.phone;
        if (isRequestedPhoneNumberChange && verificationCode) {
            this.requestDataChange.emit({
                payload: verificationCode,
                actionType: DataChangeActionType.SaveNewPhoneNumber,
            });
        } else if (!isRequestedPhoneNumberChange && phone.number) {
            this.sendPhoneVerificationCode();
        } else {
            this.phoneNumberFormControl.markAllAsTouched();
        }
    }

    protected sendPhoneVerificationCode(): void {
        this.requestDataChange.emit({
            payload: this.phoneNumberFormControl.value.phone,
            actionType: DataChangeActionType.RequestPhoneNumberChange,
        });
    }

    protected closeModifyPhoneNumberPanel(): void {
        this.phoneNumberFormControl.reset();
        this.closePanel.emit();
    }
}
