import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxMaskModule } from "ngx-mask";
import { SharedUiModule } from "../../shared-ui.module";
import { LanguageCode } from "../i18n";
import { getTranslocoInlineLoader, SharedI18nModule } from "../i18n/shared-i18n.module";
import { DmsCoordinatesInputComponent } from "./components/dms-coordinates-input/dms-coordinates-input.component";
import { DmsDisplayComponent } from "./components/dms-display/dms-display.component";

@NgModule({
    imports: [
        CommonModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        SharedI18nModule,
        SharedUiModule,
        NgxMaskModule.forRoot(),
    ],
    declarations: [DmsCoordinatesInputComponent, DmsDisplayComponent],
    exports: [DmsCoordinatesInputComponent, DmsDisplayComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmUiDmsCoordinatesInput",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class DmsCoordinatesModule {}
