import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { DateFormatOptions } from "@ngneat/transloco-locale";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

interface DateRangeDisplayComponentState {
    startDate: Date | undefined;
    endDate: Date | undefined;
    dateStyle: DateFormatOptions["dateStyle"];
}

@Component({
    selector: "dtm-ui-date-range-display[startDate][endDate]",
    templateUrl: "./date-range-display.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DateRangeDisplayComponent {
    protected readonly startDate$ = this.localStore.selectByKey("startDate");
    protected readonly endDate$ = this.localStore.selectByKey("endDate");
    protected readonly isSameDay$ = combineLatest([this.startDate$, this.endDate$]).pipe(
        map(([startDate, endDate]) => startDate?.toLocaleDateString() === endDate?.toLocaleDateString())
    );
    protected readonly dateStyle$ = this.localStore.selectByKey("dateStyle");

    @Input() public set startDate(value: Date) {
        this.localStore.patchState({ startDate: value });
    }

    @Input() public set endDate(value: Date) {
        this.localStore.patchState({ endDate: value });
    }

    @Input() public set dateStyle(value: DateFormatOptions["dateStyle"]) {
        this.localStore.patchState({ dateStyle: value });
    }

    constructor(private readonly localStore: LocalComponentStore<DateRangeDisplayComponentState>) {
        this.localStore.setState({
            startDate: undefined,
            endDate: undefined,
            dateStyle: "full",
        });
    }
}
