import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoDatePipe } from "@ngneat/transloco-locale";

@Pipe({
    name: "messageDateDisplay",
})
export class MessageDateDisplayPipe extends TranslocoDatePipe implements PipeTransform {
    public transform(value: string): string {
        const messageDate = new Date(value).toDateString();
        const today = new Date().toDateString();
        const isMessagePreviousYear = new Date().getFullYear() > new Date(value).getFullYear();

        if (isMessagePreviousYear) {
            return super.transform(new Date(value), { day: "numeric", month: "short", year: "numeric" });
        }

        if (messageDate === today) {
            return super.transform(new Date(value), { timeStyle: "short" });
        } else {
            return super.transform(new Date(value), { day: "numeric", month: "short" });
        }
    }
}
