import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorIntl as MatPaginatorIntl } from "@angular/material/legacy-paginator";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { first } from "rxjs/operators";
import { Page, PAGE_SIZE_OPTIONS } from "./pagination.models";

interface PageRange {
    startIndex: number;
    endIndex: number;
    length: number;
}

interface PaginationComponentState {
    pageRange: PageRange | undefined;
    areResultsAvailable: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-pagination[pagination]",
    templateUrl: "./pagination.component.html",
    styleUrls: ["./pagination.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/no-host-metadata-property
    host: {
        "[class.mat-paginator]": "false",
    },
    providers: [LocalComponentStore],
})
export class PaginationComponent extends MatPaginator {
    protected readonly pageRange$ = this.localStore.selectByKey("pageRange");
    protected readonly areResultsAvailable$ = this.localStore.selectByKey("areResultsAvailable");

    @Input() public set pagination(value: Page | undefined) {
        this.pageSize = value?.pageSize;
        this.pageIndex = value?.pageNumber;
        this.length = value?.totalElements;

        this.updateRangeLabel();
    }

    constructor(
        matPaginatorIntl: MatPaginatorIntl,
        cdr: ChangeDetectorRef,
        private readonly localStore: LocalComponentStore<PaginationComponentState>
    ) {
        super(matPaginatorIntl, cdr);

        this.localStore.setState({
            pageRange: undefined,
            areResultsAvailable: false,
        });

        this.initialized.pipe(first(), untilDestroyed(this)).subscribe(() => this.initialize());
    }

    private initialize(): void {
        this.pageSizeOptions = this.pageSizeOptions?.length ? this.pageSizeOptions : PAGE_SIZE_OPTIONS;
    }

    private updateRangeLabel(page: number = this.pageIndex, pageSize: number = this.pageSize, length: number = this.length): void {
        if (length === 0) {
            this.localStore.patchState({
                areResultsAvailable: false,
            });

            return;
        }

        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        this.localStore.patchState({
            areResultsAvailable: true,
            pageRange: { startIndex: startIndex + 1, endIndex, length },
        });
    }
}
