import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { finalize, Observable, Subject, throttleTime } from "rxjs";

export enum OrientationType {
    Clockwise = "Clockwise",
    CounterClockwise = "CounterClockwise",
}
export interface OrientationDetails {
    type: OrientationType;
    value: number;
}
const DEVICE_ORIENTATION_THROTTLE_TIME = 100;

@Injectable()
export class DeviceOrientationService {
    private deviceOrientation = new Subject<OrientationDetails>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private updateDeviceOrientationAbsolute = ({ alpha }: any = {}) => {
        if (alpha === null) {
            return;
        }

        this.deviceOrientation.next({ value: alpha, type: OrientationType.CounterClockwise });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private updateDeviceOrientation = ({ webkitCompassHeading }: any = {}) => {
        if (webkitCompassHeading === undefined || webkitCompassHeading === null) {
            return;
        }

        this.deviceOrientation.next({ value: webkitCompassHeading, type: OrientationType.Clockwise });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(@Inject(DOCUMENT) private document: any) {}

    public getDeviceOrientation(): Observable<OrientationDetails> {
        if (this.document.defaultView?.ondeviceorientationabsolute !== undefined) {
            addEventListener("deviceorientationabsolute", this.updateDeviceOrientationAbsolute);
        } else {
            addEventListener("deviceorientation", this.updateDeviceOrientation);
        }

        return this.deviceOrientation.asObservable().pipe(
            throttleTime(DEVICE_ORIENTATION_THROTTLE_TIME),
            finalize(() => {
                removeEventListener("deviceorientationabsolute", this.updateDeviceOrientationAbsolute);
                removeEventListener("deviceorientation", this.updateDeviceOrientation);
            })
        );
    }
}
