import { ChangeDetectorRef, Directive, Inject, OnInit, Optional, Self } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
    selector: "[dtmUiMarkValueAccessorControlsAsTouched]",
})
export class MarkValueAccessorControlsAsTouchedDirective implements OnInit {
    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        @Inject(NgControl)
        @Optional()
        private ngControl?: NgControl,
        @Optional()
        @Self()
        @Inject(NG_VALUE_ACCESSOR)
        private valueAccessors?: ControlValueAccessor[]
    ) {}

    public ngOnInit(): void {
        if (!this.ngControl || !this.ngControl.control || !this.valueAccessors || this.valueAccessors.length !== 1) {
            return;
        }

        const controls: AbstractControl[] = Object.values(this.valueAccessors[0]).filter(
            (value: unknown) => value instanceof AbstractControl
        );

        if (controls.length === 0) {
            return;
        }

        this.ngControl.control.markAllAsTouched = (): void => {
            controls.forEach((control) => control.markAllAsTouched());
            // TODO consider zoneless alternative for markForCheck REJ-1052
            this.changeDetectorRef.markForCheck();
        };
    }
}
