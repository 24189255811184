<ng-container *ngrxLet="{ dateFormatOptions: dateFormatOptions$, startTime: startTime$, endTime: endTime$, isSameTime: isSameTime$ } as vm">
    <ng-container *ngIf="vm.startTime && vm.endTime">
            <ng-container *ngIf="vm.isSameTime; else differentTimeTemplate">
                {{ vm.startTime | localizeDate : vm.dateFormatOptions }}
            </ng-container>
            <ng-template #differentTimeTemplate>
                {{ vm.startTime | localizeDate : vm.dateFormatOptions }} -
                {{ vm.endTime | localizeDate : vm.dateFormatOptions }}
            </ng-template>
    </ng-container>
</ng-container>
