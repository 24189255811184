import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { ToastrService } from "ngx-toastr";

interface LabelValueComponentState {
    label: string | undefined;
    labelHint: string | undefined;
    value: string | undefined;
    canCopy: boolean;
}

@Component({
    selector: "dtm-ui-label-value[label]",
    templateUrl: "./label-value.component.html",
    styleUrls: ["./label-value.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LabelValueComponent {
    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }
    @Input() public set labelHint(value: string | undefined) {
        this.localStore.patchState({ labelHint: value });
    }
    @Input() public set value(value: string | undefined) {
        this.localStore.patchState({ value: value });
    }
    @Input() public set canCopy(value: BooleanInput) {
        this.localStore.patchState({ canCopy: coerceBooleanProperty(value) });
    }

    public readonly label$ = this.localStore.selectByKey("label").pipe(RxjsUtils.filterFalsy());
    public readonly labelHint$ = this.localStore.selectByKey("labelHint").pipe(RxjsUtils.filterFalsy());
    public readonly value$ = this.localStore.selectByKey("value").pipe(RxjsUtils.filterFalsy());
    public readonly canCopy$ = this.localStore.selectByKey("canCopy");

    constructor(
        private readonly localStore: LocalComponentStore<LabelValueComponentState>,
        private readonly toastService: ToastrService,
        private readonly transloco: TranslocoService
    ) {
        localStore.setState({ value: undefined, label: undefined, labelHint: undefined, canCopy: false });
    }

    protected handleValueCopy(isSuccess: boolean) {
        if (isSuccess) {
            this.toastService.success(this.transloco.translate("dtmUi.copyToClipboard.successMessage"));
        } else {
            this.toastService.error(this.transloco.translate("dtmUi.copyToClipboard.errorMessage"));
        }
    }
}
