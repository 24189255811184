import { Pipe, PipeTransform } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";

@Pipe({
    name: "finalRisk",
})
export class FinalRiskPipe implements PipeTransform {
    constructor(private readonly translocoService: TranslocoService) {}

    public transform(finalRisk: number | undefined): string | undefined {
        if (finalRisk === undefined || finalRisk === null) {
            return undefined;
        }

        return this.translocoService.translate("dtmUi.finalRisk", { value: finalRisk });
    }
}
