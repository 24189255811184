import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Input, ViewChild } from "@angular/core";
import {
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { ArrayUtils, FunctionUtils, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { merge } from "rxjs";
import { map } from "rxjs/operators";

interface SerialNumbersControlComponentState {
    serialNumbers: string[];
    isSingle: boolean;
}

const MAX_SERIAL_NUMBER_LENGTH = 50;
const COLLECTION_SEPARATOR = ",";

@UntilDestroy()
@Component({
    selector: "dtm-ui-serial-numbers-control",
    templateUrl: "./serial-numbers-control.component.html",
    styleUrls: ["./serial-numbers-control.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SerialNumbersControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SerialNumbersControlComponent),
            multi: true,
        },
    ],
})
export class SerialNumbersControlComponent implements ControlValueAccessor, Validator {
    @Input() public set isSingle(value: BooleanInput) {
        this.localStore.patchState({ isSingle: coerceBooleanProperty(value) });
    }

    @Input() public set serialNumbers(value: string[] | null) {
        const serialNumbers = value ?? [];
        this.localStore.patchState({ serialNumbers });
        this.control.setValue(serialNumbers.join(COLLECTION_SEPARATOR + " "));
    }

    @ViewChild("singleSerialNumberInput") private readonly singleSerialNumberInput!: ElementRef;
    @ViewChild("serialNumbersTextarea") private readonly serialNumbersTextarea!: ElementRef;

    private get control(): FormControl<string | null> {
        const isSingle = this.localStore.selectSnapshotByKey("isSingle");

        if (isSingle) {
            return this.singleSerialNumberControl;
        }

        return this.serialNumbersControl;
    }

    protected readonly isSingle$ = this.localStore.selectByKey("isSingle");
    protected readonly amount$ = this.localStore.selectByKey("serialNumbers").pipe(map((serialNumbers) => serialNumbers.length));

    protected readonly singleSerialNumberControl = new FormControl<string | null>(null, {
        validators: [
            Validators.required,
            Validators.maxLength(MAX_SERIAL_NUMBER_LENGTH),
            Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
        ],
    });
    protected readonly serialNumbersControl = new FormControl<string | null>(null, {
        validators: [Validators.required, (control: FormControl<string | null>) => this.getSerialNumbersCollectionValidator(control)],
    });

    private onTouched = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;
    private propagateChange: (value: string[]) => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<SerialNumbersControlComponentState>) {
        this.localStore.setState({
            serialNumbers: [],
            isSingle: true,
        });

        merge(
            this.singleSerialNumberControl.valueChanges.pipe(map((serialNumber) => (serialNumber ? [serialNumber] : []))),
            this.serialNumbersControl.valueChanges.pipe(
                map((serialNumbersString) =>
                    serialNumbersString ? ArrayUtils.unique(this.getSerialNumbersFromString(serialNumbersString)) : []
                )
            )
        )
            .pipe(untilDestroyed(this))
            .subscribe((serialNumbers) => {
                this.onTouched();
                this.onValidationChange();

                if (this.validate() || !serialNumbers.length) {
                    return;
                }

                this.localStore.patchState({ serialNumbers });
                this.propagateChange(serialNumbers);
            });
    }

    public registerOnChange(fn: (value: string[]) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    public writeValue(value: string[] | null): void {
        this.serialNumbers = value ?? [];
    }

    public validate(): ValidationErrors | null {
        return this.control.errors;
    }

    public focus() {
        const isSingle = this.localStore.selectSnapshotByKey("isSingle");

        if (isSingle) {
            this.singleSerialNumberInput?.nativeElement.focus();
        } else {
            this.serialNumbersTextarea?.nativeElement.focus();
        }
    }

    private getSerialNumbersFromString(serialNumbersString: string): string[] {
        return serialNumbersString
            .split(COLLECTION_SEPARATOR)
            .map((value) => value.trim())
            .filter(FunctionUtils.isTruthy);
    }

    private getSerialNumbersCollectionValidator({ value }: FormControl<string | null>): ValidationErrors | null {
        const serialNumbers = this.getSerialNumbersFromString(value ?? "");

        const validSerialNumbers: string[] = [];
        for (const serialNumber of serialNumbers) {
            if (this.isSerialNumberLengthInvalid(serialNumber)) {
                return { serialNumberMaxLength: { requiredLength: MAX_SERIAL_NUMBER_LENGTH } };
            } else if (validSerialNumbers.includes(serialNumber)) {
                return { serialNumberDuplicated: { serialNumber } };
            } else {
                validSerialNumbers.push(serialNumber);
            }
        }

        if (!validSerialNumbers.length) {
            return { required: true };
        }

        return null;
    }

    private isSerialNumberLengthInvalid(serialNumber: string): boolean {
        return serialNumber.length > MAX_SERIAL_NUMBER_LENGTH;
    }
}
