import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { first, map, Observable, switchMap } from "rxjs";
import { LANGUAGE_CONFIGURATION } from "./i18n.models";
import { I18nService } from "./services/i18n.service";

@UntilDestroy()
@Injectable()
export class DtmLanguageInterceptor implements HttpInterceptor {
    constructor(private readonly i18nService: I18nService) {}

    public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this.i18nService.currentLanguage$.pipe(
            first(),
            map((language) => LANGUAGE_CONFIGURATION.localeMapping[language]),
            switchMap((language) => {
                if (language) {
                    httpRequest = httpRequest.clone({ setHeaders: { "Accept-Language": language } });
                }

                return next.handle(httpRequest);
            })
        );
    }
}
