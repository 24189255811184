import { MILLISECONDS_IN_DAY, MILLISECONDS_IN_HOUR, MILLISECONDS_IN_MINUTE, MILLISECONDS_IN_SECOND } from "../unit-utils/time";
export interface DiffDate {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export function calculateDiffDate(firstDate: Date | string, secondDate: Date | string): DiffDate {
    firstDate = new Date(firstDate);
    secondDate = new Date(secondDate);

    let diff = Math.abs(firstDate.getTime() - secondDate.getTime());

    const days = Math.floor(diff / MILLISECONDS_IN_DAY);
    diff -= days * MILLISECONDS_IN_DAY;

    const hours = Math.floor(diff / MILLISECONDS_IN_HOUR);
    diff -= hours * MILLISECONDS_IN_HOUR;

    const minutes = Math.floor(diff / MILLISECONDS_IN_MINUTE);
    diff -= minutes * MILLISECONDS_IN_MINUTE;

    const seconds = Math.floor(diff / MILLISECONDS_IN_SECOND);

    return {
        days,
        hours,
        minutes,
        seconds,
    };
}
