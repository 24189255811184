import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { LanguageCode, LANGUAGE_CONFIGURATION } from "../modules/i18n";
import { UIStateSyncService } from "./../services/ui-state-sync/ui-state-sync.service";
import { UIActions } from "./ui.actions";

export interface UIStateModel {
    isMenuCollapsed: boolean;
    activeLanguage: LanguageCode;
}

@State<UIStateModel>({
    name: "ui",
    defaults: {
        isMenuCollapsed: false,
        activeLanguage: LANGUAGE_CONFIGURATION.defaultLanguage,
    },
})
@Injectable()
export class UIState {
    constructor(private readonly uiStateSyncService: UIStateSyncService) {}

    @Selector()
    public static isMenuCollapsed(state: UIStateModel): boolean {
        return state.isMenuCollapsed;
    }

    @Selector()
    public static activeLanguage(state: UIStateModel): LanguageCode {
        return state.activeLanguage;
    }

    @Action(UIActions.SetMenuCollapsedState)
    public setMenuCollapsedState(context: StateContext<UIStateModel>, action: UIActions.SetMenuCollapsedState): void {
        context.patchState({ isMenuCollapsed: action.isCollapsed });
    }

    @Action(UIActions.SetActiveLanguage)
    public setActiveLanguage(context: StateContext<UIStateModel>, action: UIActions.SetActiveLanguage): void {
        context.patchState({ activeLanguage: action.languageCode });
        this.uiStateSyncService.setActiveLanguage(action.languageCode);
    }
}
