<dtm-ui-input-field [isClearable]="(isClearable$ | ngrxPush) ?? false">
    <ng-content select="label" ngProjectAs="label"></ng-content>
    <input
        class="time-field"
        matInput
        autocomplete="off"
        [formControl]="timeFormControl"
        type="time"
        #timeInput
        [required]="!!(isRequired$ | ngrxPush)"
    />
    <dtm-ui-icon class="field-suffix time-icon" name="time" (click)="timeInput.focus()"></dtm-ui-icon>
    <ng-content select=".field-hint" ngProjectAs=".field-hint"></ng-content>
    <ng-content select=".field-error" ngProjectAs=".field-error"></ng-content>
</dtm-ui-input-field>
