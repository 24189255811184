import { Injectable } from "@angular/core";
import { LegacyNativeDateAdapter as NativeDateAdapter } from "@angular/material/legacy-core";
import { LanguageDefinition, LANGUAGE_CONFIGURATION, LOCALE_MAPPING } from "../../modules/i18n";

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    public getFirstDayOfWeek(): number {
        const languageDefinition = this.getCurrentLanguageDefinition();

        return languageDefinition?.firstDayOfWeek ?? super.getFirstDayOfWeek();
    }

    public parse(value: string | number): Date | null {
        const languageDefinition = this.getCurrentLanguageDefinition();

        if (languageDefinition) {
            return languageDefinition.dateParser(value);
        }

        return super.parse(value);
    }

    private getCurrentLanguageDefinition(): LanguageDefinition | null {
        // TODO: DTM-3987 remove forcing en for locale === en-GB when backend will change it's locale
        const currentLanguageAlpha2Code =
            this.locale === "en-GB" ? "en" : Object.keys(LOCALE_MAPPING).find((alpha2Code) => LOCALE_MAPPING[alpha2Code] === this.locale);

        return (
            LANGUAGE_CONFIGURATION.languageDefinitions.find(
                (entry: LanguageDefinition) => entry.alpha2Code === currentLanguageAlpha2Code
            ) ?? null
        );
    }
}
