import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";

@Directive({
    selector: "[dtmUiAddChildrenAriaLabels]",
})
export class AddChildrenAriaLabelsDirective implements OnInit, OnDestroy {
    @Input("dtmUiAddChildrenAriaLabels") public ariaLabelConfig: { [selector: string]: string } = {};

    private observer: MutationObserver | undefined;

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    public ngOnInit(): void {
        this.observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
                    this.addAriaLabelToNewChildren(mutation.addedNodes);
                }
            });
        });

        this.observer.observe(this.element.nativeElement, {
            childList: true,
            subtree: true,
        });
    }

    public ngOnDestroy(): void {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    private addAriaLabelToNewChildren(nodes: NodeList): void {
        nodes.forEach((node) => {
            const element = node as HTMLElement;
            if (element.nodeType !== Node.ELEMENT_NODE) {
                return;
            }
            Object.entries(this.ariaLabelConfig).forEach(([selector, ariaLabel]) => {
                if (element.matches(selector)) {
                    this.renderer.setAttribute(element, "aria-label", ariaLabel);
                }
                const matchingElements = element.querySelectorAll(selector);
                matchingElements.forEach((matchingElement) => {
                    this.renderer.setAttribute(matchingElement, "aria-label", ariaLabel);
                });
            });
        });
    }
}
